h1 {
  text-align: center;
}

/* Dropdown Button */
.dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

#unselectAll {
  position: absolute;
  top: 0px;
  right: 0px;
}

.graph {
  position: relative;
  border-style: solid;
  border-radius: 5px;
}

.areaChartTitle {
  text-anchor: middle;
}

* {
  font-family: 'Open Sans', sans-serif;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  /* display: inline-block; */
  float: right;
  margin-right: 60px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

#viz-full {
  display: flex;
}

#viz-full:first-child {
    margin-right: 20px;
} 

#viz-container {
  flex: 1;
  display: flex;
  align-items: center;
}

#viz-container2 {
  flex: 1;
}
